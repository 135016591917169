export const GROUPBY_FIELDS = [
  'country',
  'regional',
  'region',
  'mgmt',
  'supervision',
  'store',
  'businessDate',
  'companyName',
  'agreementId',
  'time',
];

export const GROUPBY_DEFAULT = ['country', 'store', 'timeInterval'];

export const GROUPBY_REPORT = ['businessDate', 'time'];

export const GROUPBY_OPSTRUCTURE = [
  'regional',
  'region',
  'mgmt',
  'supervision',
  'companyName',
  'agreementId',
];

export const STORE_AGREEMENTS = ['1', '2', '3', 'NULL'];

export const MONTHS = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

export const REGION_PREFIX: { [key: string]: string } = {
  'Federico Saevich - Dir Ops': 'MEX S.',
  'Uriel Herrera - Dir Ops': 'MEX H.',
  'REGIONAL BRA': 'BRA-BRA',
  'REGIONAL RSOU': 'BRA-RSOU',
  'REGIONAL SAO1': 'BRA-SAO1',
  'REGIONAL SAO2': 'BRA-SAO2',
};

export const MGMT_PREFIX: { [key: string]: string } = {
  'VINCENT LAMAZOU-St. Thomas': 'STT',
  'VINCENT LAMAZOU-St. Croix': 'STX',
};

export const codedErrors = [
  'exceeded_number_of_records',
  'exceeded_number_of_records_exclusions',
  'invalid_parameters',
  'popup_window_error',
  'invalid_token',
  'Network_Error',
];