import React from 'react';
import {
  makeStyles,
  Theme,
  createStyles,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Checkbox,
  ListItemText,
  useTheme,
  useMediaQuery,
} from '@material-ui/core';
import { useReport } from '../../../context/report';
import { useTranslation } from 'react-i18next';
import { ReportGrouping } from '../../../types';
import { useUser } from '../../../context/user';
import {
  GROUPBY_DEFAULT,
  GROUPBY_OPSTRUCTURE,
  GROUPBY_REPORT,
} from '../../../utils/constants';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
      [theme.breakpoints.down('sm')]: {
        minWidth: 90,
        maxWidth: 160,
      },
    },
  })
);

interface GroupSelector {
  noLabel?: boolean;
}

const GroupSelector = ({ noLabel }: GroupSelector) => {
  const { filters, updateFilters } = useReport();
  const classes = useStyles();
  const { t } = useTranslation();
  const { permissions } = useUser();
  const theme = useTheme();
  const smDown = useMediaQuery(theme.breakpoints.down('sm'));
  const canFilterOperativeStructure = permissions.includes('FILTER_OPSTRUCTURE');

  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    updateFilters({
      ...filters,
      groupBy: [...(event.target.value as ReportGrouping[])],
    });
  };

  const renderValue = (selected: string[]) => {
    if (smDown) {
      if ((selected as string[]).length > 1) {
        return `${(selected as string[]).length} ${t('shared.labels.selected')}`;
      } else {
        return t(`reports.labels.${(selected as string[])[0]}`);
      }
    } else {
      return (selected as string[]).map(option => t(`reports.labels.${option}`)).join(', ');
    }
  };

  const getOptions = () => {
    if (canFilterOperativeStructure)
      return GROUPBY_DEFAULT.concat(GROUPBY_REPORT).concat(GROUPBY_OPSTRUCTURE);
    return GROUPBY_DEFAULT.concat(GROUPBY_REPORT);
  };

  return (
    <FormControl className={classes.formControl}>
      {!noLabel && <InputLabel id="input-label-grouping">{t('toolbar.labels.groupBy')}</InputLabel>}
      <Select
        labelId="input-label-select-grouping"
        id="select-grouping"
        value={filters.groupBy.filter(g => g !== 'beginTime' && g !== 'hour')}
        onChange={handleChange}
        renderValue={selected => renderValue(selected as string[])}
        multiple
      >
        {getOptions().map(option => (
          <MenuItem key={`menu-option-${option}`} value={option} disabled={option === 'country'}>
            <Checkbox checked={filters.groupBy.indexOf(option as ReportGrouping) > -1} />
            <ListItemText primary={t(`reports.labels.${option}`)} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default GroupSelector;
