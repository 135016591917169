import React, { useState } from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import { CssBaseline } from '@material-ui/core';
import { useAlert } from '../../context/alert';
import { useTranslation } from 'react-i18next';
import Header from './header';
import Sidebar from './sidebar';
import ContentRouter from './router';
import { ReportProvider } from '../../context/report';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      [theme.breakpoints.up('sm')]: {
        display: 'flex',
      },
    },
    toolbar: theme.mixins.toolbar,
    sidebarLayout: {
      flexGrow: 1,
      padding: theme.spacing(3),
      [theme.breakpoints.down('sm')]: {
        marginBottom: theme.spacing(11),
      },
    },
    noSidebarLayout: {
      flexGrow: 1,
      padding: theme.spacing(3),
    },
    content: {
      overflowX: 'auto',
      flexGrow: 1,
      padding: theme.spacing(3),
    },
  })
);

const AppShell = () => {
  const classes = useStyles();
  const { showAlert } = useAlert();
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);

  const toggleDrawer = () => setOpen(o => !o);

  document.addEventListener('new-version', () => {
    sessionStorage.removeItem('masterOperativeData');
    showAlert(t('alerts.newVersion.title'), t('alerts.newVersion.body'), [
      {
        label: t('alerts.newVersion.refreshAction'),
        handleClick: () => {
          window.location.reload();
        },
      },
    ]);
  });

  return (
    <div className={classes.root}>
      <ReportProvider>
        <CssBaseline />
        <Header title="Speed of Service" toggleDrawer={toggleDrawer} />
        <Sidebar isOpen={open} toggle={toggleDrawer} />
        <main className={classes.content}>
          <div className={classes.toolbar} />
          <ContentRouter />
        </main>
      </ReportProvider>
    </div>
  );
};

export default AppShell;
