import React, { FunctionComponent } from 'react';
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';
import AppShell from './components/app-shell';
import Login from './components/login';
import LoadingPage from './components/shared/LoadingPage';
import { useAuthentication } from './context/authentication';
import { useUser } from './context/user';
import { AppPermission } from './types';

const Router = () => (
  <BrowserRouter>
    <Switch>
      <Route exact path="/" component={Login} />
      <PrivateRoute
        path={[
          '/madeforyou',
          '/frontcounter',
          '/drivethru',
          '/profile',
          '/ranking',
          '/help',
          '/exclusions',
          '/franchisees',
        ]}
        component={AppShell}
      />
      <Redirect to="/" />
    </Switch>
  </BrowserRouter>
);

interface PrivateRouteProps {
  component: FunctionComponent | null;
  path: string | string[];
  restriction?: AppPermission;
}

export const PrivateRoute = ({ component, path, restriction }: PrivateRouteProps) => {
  const { isAuthenticated, loading } = useAuthentication();
  const { loading: userLoading, permissions } = useUser();

  if (restriction && !permissions.includes(restriction)) return null;

  if (component === null) return null;

  if (loading || userLoading) return <LoadingPage />;

  return isAuthenticated ? <Route path={path} component={component} /> : <Redirect to="/" />;
};

export default Router;
