import React, { useState } from 'react';
import Toolbar from '../../shared/Toolbar';
import { Column, FranchiseesReport } from '../../../types';
import { useTranslation } from 'react-i18next';
import { useReport } from '../../../context/report';
import { Grid } from '@material-ui/core';
import ReportTable from '../../shared/Table';
import FloatingActionButton from '../../fab';
import FilterListIcon from '@material-ui/icons/FilterList';
import FiltersDialog from '../../shared/dialogs/filters-dialog';
import withRestriction from '../../restricted';
import { quarterlySegments } from '../../../utils/segments';
import FranchiseesFormDialog from '../../shared/dialogs/franchisees-form-dialog';

const FReport = () => {
  const { t } = useTranslation();
  const {
    updateFilters,
    franshisees,
    getFranchiseesReport,
    newFiltersApplied,
    filters,
    numberOfAppliedFilters,
    loading,
  } = useReport();
  const [openReportSettings, setOpenReportSettings] = useState(false);
  const [openForm, setOpenForm] = useState(false);
  const [selectedRow, setSelectedRow] = useState<FranchiseesReport>();

  const handleAddClick = () => {
    setSelectedRow(undefined);
    setOpenForm(true);
  };

  const handleRowClick = (data: FranchiseesReport) => {
    setSelectedRow(data);
    setOpenForm(true);
  };

  const handleCloseForm = () => {
    setOpenForm(false);
    setSelectedRow(undefined);
    updateFilters({ ...filters, segments: quarterlySegments });
  };

  const reportColumns: Column<FranchiseesReport>[] = [
    {
      field: 'country',
      label: t('reports.labels.country'),
      type: 'string',
      format: row => (row.country && t(`shared.labels.${row.country}`)) || '0',
    },
    {
      field: 'email',
      label: 'Email',
      type: 'string',
    },
    {
      field: 'companyId',
      label: t('reports.labels.companyId'),
      type: 'string',
    },
  ];

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Toolbar
            getData={() => getFranchiseesReport()}
            dataIsLoading={loading}
            handleAdd={handleAddClick}
          />
        </Grid>
        <Grid item xs={12}>
          <ReportTable
            columns={reportColumns}
            data={franshisees}
            loading={loading}
            newFiltersApplied={newFiltersApplied}
            title={t('reports.labels.franchiseesSettings')}
            clickeable
            onRowClick={handleRowClick}
          />
        </Grid>
      </Grid>
      <FloatingActionButton
        title={t('navigation.buttons.filters', { number: numberOfAppliedFilters })}
        handleClick={() => setOpenReportSettings(true)}
        handleCreateExclusion={() => setOpenForm(true)}
        icon={<FilterListIcon />}
      />
      <FiltersDialog
        open={openReportSettings}
        setOpen={setOpenReportSettings}
        setOpenExclusions={setOpenForm}
        fetchData={() => getFranchiseesReport()}
      />
      <FranchiseesFormDialog
        open={openForm}
        handleClose={handleCloseForm}
        data={selectedRow}
        loading={loading}
      />
    </>
  );
};

export default withRestriction(FReport, 'SCREEN_FRANCHISE_SETTINGS');
