/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-empty-function */

import React, {
  createContext,
  ReactNode,
  useState,
  useContext,
  useEffect,
  Dispatch,
  SetStateAction,
} from 'react';
import { format } from 'date-fns';
import {
  ReportFrequency,
  FormattedFilters,
  ReportFilters,
  FilterLabels,
  StoreAgreement,
  SOSExclusions,
  FranchiseesReport,
} from '../types';
import { hourlySegments, quarterlySegments } from '../utils/segments';
import { useLocation } from 'react-router-dom';
import { useTheme } from '@material-ui/core';
import { STORE_AGREEMENTS } from '../utils/constants';
import { useTranslation } from 'react-i18next';
import { useApi } from './api';
import { useAlert } from './alert';
import { getExclusionRecordsLimitError, sortedByLastUpdate } from '../utils/functions';
import { AxiosError } from 'axios';

interface ReportContext {
  filters: ReportFilters;
  frequency: ReportFrequency;
  newFiltersApplied: boolean;
  setNewFiltersApplied: Dispatch<SetStateAction<boolean>>;
  numberOfAppliedFilters: number;
  updateFilters: (newFilters: Partial<ReportFilters>) => void;
  updateFrequency: (selectedFrequency: ReportFrequency) => void;
  getFormattedFilters: (inExclusions?: boolean) => FormattedFilters;
  getFilterLabels: () => FilterLabels;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getColumnColor: (value: any) => string;
  sosExclusions?: SOSExclusions[];
  getExclusionsReport: () => Promise<void>;
  createExclusionsReport: (data: Partial<SOSExclusions>) => Promise<void>;
  updateExclusionsReport: (data: Partial<SOSExclusions>) => Promise<void>;
  deleteExclusionsReport: (userUpdate: string, id: string) => Promise<void>;

  franshisees: FranchiseesReport[];
  getFranchiseesReport: () => Promise<void>;
  setFranchisee: (data: FranchiseesReport) => Promise<void>;
  deleteFranchisee: (email: string) => Promise<void>;

  loading: boolean;
}

const initialState: ReportContext = {
  filters: {
    groupBy: ['country'],
    division: [],
    countries: [],
    regionalManagements: [],
    regions: [],
    managements: [],
    supervisions: [],
    franchises: [],
    stores: [],
    segments: quarterlySegments,
    businessDay: new Date(),
    fromDate: new Date(),
    toDate: new Date(),
    rankingField: 'mgmt',
    storeAgreements: [],
    year: new Date().getFullYear().toString(),
    service: 'MFY',
    disableToDate: false,
  },
  frequency: 'hour',
  numberOfAppliedFilters: 0,
  newFiltersApplied: false,
  updateFilters: (_updatedFilters: Partial<ReportFilters>) => {},
  setNewFiltersApplied: () => {},
  updateFrequency: () => {},
  getColumnColor: () => '',
  getFormattedFilters: () => ({
    groupBy: '',
    division: '',
    country: '',
    regional: '',
    region: '',
    mgmt: '',
    supervision: '',
    store: '',
    frequency: '',
    fromDate: '',
    toDate: '',
  }),
  getFilterLabels: () => ({}),
  sosExclusions: undefined,
  getExclusionsReport: () => new Promise<void>(() => {}),
  createExclusionsReport: () => new Promise<void>(() => {}),
  updateExclusionsReport: () => new Promise<void>(() => {}),
  deleteExclusionsReport: () => new Promise<void>(() => {}),

  franshisees: [],
  getFranchiseesReport: () => new Promise<void>(() => {}),
  setFranchisee: () => new Promise<void>(() => {}),
  deleteFranchisee: () => new Promise<void>(() => {}),

  loading: false,
};

const ReportContext = createContext(initialState);

interface ExclusionActionHandler {
  action: 'create' | 'update' | 'delete';
  data?: Partial<SOSExclusions>;
  id?: string;
}
interface Provider {
  children: ReactNode;
}

export const ReportProvider = ({ children }: Provider) => {
  const { t, i18n } = useTranslation();
  const { showError } = useAlert();
  const {
    fetchExclusionsReport,
    fetchCreateExclusion,
    fetchUpdateExclusion,
    fetchDeleteExclusion,
    fetchFranchiseesReport,
    fetchSetFranchiseesReport,
    fetchDeleteFranchisee,
  } = useApi();
  const [loading, setLoading] = useState(false);
  const [filters, setFilters] = useState<ReportFilters>(initialState.filters);
  const [frequency, setFrequency] = useState<ReportFrequency>('beginTime');
  const [newFiltersApplied, setNewFiltersApplied] = useState(false);
  const [numberOfAppliedFilters, setNumberOfAppliedFilters] = useState(0);
  const [sosExclusions, setSosExclusions] = useState<SOSExclusions[] | undefined>([]);
  const [franshisees, setFranchisees] = useState<FranchiseesReport[]>([]);
  const location = useLocation();
  const theme = useTheme();

  useEffect(() => {
    let newNumber = 0;
    filters.division.length > 0 && newNumber++;
    filters.countries.length > 0 && newNumber++;
    filters.regionalManagements.length > 0 && newNumber++;
    filters.regions.length > 0 && newNumber++;
    filters.managements.length > 0 && newNumber++;
    filters.supervisions.length > 0 && newNumber++;
    filters.stores.length > 0 && newNumber++;
    filters.franchises.length > 0 && newNumber++;
    filters.storeAgreements.length > 0 && newNumber++;
    setNumberOfAppliedFilters(newNumber);
  }, [filters]);

  useEffect(() => {
    setNewFiltersApplied(true);
  }, [filters]);

  useEffect(() => {
    setFilters(filters => ({ ...filters, countries: [] }));
  }, [filters.division]);

  useEffect(() => {
    const franchises = filters.franchises.filter(f => filters.countries.includes(f.country));
    const storeAgreements = STORE_AGREEMENTS.filter(a =>
      franchises.map(f => f.agreementId?.toString() || 'NULL').includes(a)
    ) as StoreAgreement[];
    setFilters(filters => ({
      ...filters,
      regionalManagements: filters.regionalManagements.filter(rm =>
        filters.countries.includes(rm.country)
      ),
      regions: filters.regions.filter(r => filters.countries.includes(r.country)),
      managements: filters.managements.filter(m => filters.countries.includes(m.country)),
      supervisions: filters.supervisions.filter(s => filters.countries.includes(s.country)),
      franchises,
      stores: filters.stores.filter(s => filters.countries.includes(s.country)),
      storeAgreements,
    }));
    // eslint-disable-next-line
  }, [filters.countries]);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const getColumnColor = ({ value }: any) => {
    if (value <= 50) return theme.palette.success.main;
    if (value > 50 && value <= 75) return theme.palette.warning.main;
    return theme.palette.error.main;
  };

  const updateFilters = (newFilters: Partial<ReportFilters>) => {
    setFilters(f => ({ ...f, ...newFilters }));
  };

  const updateFrequency = (newFrequency: ReportFrequency) => {
    setFilters(filters => ({
      ...filters,
      segments: newFrequency === 'hour' ? hourlySegments : quarterlySegments,
    }));
    setFrequency(newFrequency);
  };

  useEffect(() => {
    updateFrequency('beginTime');
  }, [location.pathname]);

  const getFormattedFilters = (inExclusions?: boolean): FormattedFilters => {
    const groupBy: string[] = filters.groupBy.filter(x => x !== 'time' && x !== 'companyName');
    if (filters.groupBy.includes('time')) {
      if (frequency === 'beginTime') {
        groupBy.push('beginTime', 'endTime');
      } else {
        groupBy.push('hour');
      }
    }
    if (filters.groupBy.includes('companyName')) groupBy.push('company');
    const joinedCountries = filters.countries.join();
    const joinedRegionalManagements = inExclusions
      ? undefined
      : filters.regionalManagements.map(rm => rm.id).join();
    const joinedRegions = inExclusions ? undefined : filters.regions.map(r => r.id).join();
    const joinedManagements = inExclusions ? undefined : filters.managements.map(m => m.id).join();
    const joinedSupervisions = inExclusions
      ? undefined
      : filters.supervisions.map(s => s.id).join();
    const joinedStores = filters.stores.map(s => s.storeId).join();
    const joinedSegments = filters.segments.map(s => s.split(' ')[0].replace(':', '')).join();
    const joinedFranchises = inExclusions ? undefined : filters.franchises.map(f => f.id).join();
    const joinedStoreAgreements = inExclusions ? undefined : filters.storeAgreements.join();
    const from = filters.fromDate;
    const to = filters.toDate;
    const storeAcronym =
      inExclusions && filters.stores.length > 0
        ? filters.stores.map(s => s.storeAcronym).join()
        : undefined;

    return {
      division: filters.division.join(),
      groupBy: groupBy.join(),
      fromDate: format(from, 'yyyy-MM-dd'),
      toDate: !filters.disableToDate ? format(to, 'yyyy-MM-dd') : undefined,
      country: joinedCountries !== '' ? joinedCountries : undefined,
      regional: joinedRegionalManagements !== '' ? joinedRegionalManagements : undefined,
      region: joinedRegions !== '' ? joinedRegions : undefined,
      mgmt: joinedManagements !== '' ? joinedManagements : undefined,
      supervision: joinedSupervisions !== '' ? joinedSupervisions : undefined,
      company: joinedFranchises !== '' ? joinedFranchises : undefined,
      store: joinedStores !== '' ? joinedStores : undefined,
      agreement: joinedStoreAgreements !== '' ? joinedStoreAgreements : undefined,
      frequency:
        (((frequency === 'hour' && filters.segments.length < 24) ||
          (frequency === 'beginTime' && filters.segments.length < 96)) &&
          joinedSegments) ||
        undefined,
      service: filters.service,
      storeAcronym,
    };
  };

  const getFilterLabels = (): FilterLabels => {
    const groupBy =
      filters.groupBy.length > 0 ? filters.groupBy.map(g => t(`reports.labels.${g}`)) : undefined;
    const regional =
      filters.regionalManagements.length > 0
        ? filters.regionalManagements.map(rm => rm.label)
        : undefined;
    const region = filters.regions.length > 0 ? filters.regions.map(r => r.label) : undefined;
    const mgmt = filters.managements.length > 0 ? filters.managements.map(m => m.label) : undefined;
    const supervision =
      filters.supervisions.length > 0 ? filters.supervisions.map(s => s.label) : undefined;
    const company =
      filters.franchises.length > 0 ? filters.franchises.map(f => f.label) : undefined;
    const store = filters.stores.length > 0 ? filters.stores.map(s => s.storeAcronym) : undefined;
    const agreement =
      filters.storeAgreements.length > 0
        ? filters.storeAgreements.map(e => t(`shared.labels.storeAgreement.${e}`))
        : undefined;
    const resultFrequency = filters.groupBy.includes('time')
      ? getFrequencyLabel(frequency)
      : undefined;

    return {
      groupBy,
      regional,
      region,
      mgmt,
      supervision,
      company,
      store,
      agreement,
      frequency: resultFrequency,
      language: i18n.language,
    };
  };

  function getFrequencyLabel(f: ReportFrequency) {
    if (f === 'hour') return t('reports.labels.hourly');
    if (f === 'beginTime') return t('reports.labels.quarterly');
    return undefined;
  }

  const getExclusionsReport = async () => {
    try {
      setSosExclusions([]);
      setLoading(true);
      const response = (await fetchExclusionsReport(getFormattedFilters(true))).data;

      setSosExclusions(sortedByLastUpdate(response) as SOSExclusions[]);
    } catch (error) {
      const customError = getExclusionRecordsLimitError(error as AxiosError);
      showError(error, customError);
    } finally {
      setLoading(false);
      setNewFiltersApplied(false);
    }
  };

  const handleExclusionAction = ({ action, data, id }: ExclusionActionHandler) => {
    if (sosExclusions) {
      let newReport = sosExclusions.length > 0 ? [...sosExclusions] : [];
      if (action === 'create') {
        newReport.push(data as SOSExclusions);
      } else if (action === 'update' && data) {
        const itemId = id || `${data.storePK}_${data.fromDate}`;
        const index = newReport.findIndex(e => `${e.storePK}_${e.fromDate}` === itemId);
        if (index !== -1 && action === 'update') {
          newReport[index] = { ...(data as SOSExclusions) };
        }
      } else if (action === 'delete' && id) {
        newReport = newReport.filter(e => `${e.storePK}_${e.fromDate}` !== id);
      }
      setSosExclusions(sortedByLastUpdate(newReport) as SOSExclusions[]);
    }
  };

  const createExclusionsReport = async (data: Partial<SOSExclusions>) => {
    try {
      setLoading(true);
      const response = (await fetchCreateExclusion(getFormattedFilters(true), data)).data;
      // UPDATE REPORT TABLE FROM RESPONSE
      handleExclusionAction({ action: 'create', data: response });
    } catch (error) {
      const customError = getExclusionRecordsLimitError(error as AxiosError);
      showError(error, customError);
    } finally {
      setLoading(false);
      setNewFiltersApplied(false);
    }
  };

  const updateExclusionsReport = async (data: Partial<SOSExclusions>) => {
    try {
      setLoading(true);
      const response = (await fetchUpdateExclusion(getFormattedFilters(true), data)).data;
      // UPDATE REPORT TABLE FROM RESPONSE
      handleExclusionAction({ action: 'update', data: response });
    } catch (error) {
      const customError = getExclusionRecordsLimitError(error as AxiosError);
      showError(error, customError);
    } finally {
      setLoading(false);
      setNewFiltersApplied(false);
    }
  };

  const deleteExclusionsReport = async (userUpdate: string, id: string) => {
    try {
      setLoading(true);
      const response = (await fetchDeleteExclusion(userUpdate, id)).data;
      // UPDATE REPORT TABLE
      handleExclusionAction({ action: 'delete', id });
    } catch (error) {
      const customError = getExclusionRecordsLimitError(error as AxiosError);
      showError(error, customError);
    } finally {
      setLoading(false);
      setNewFiltersApplied(false);
    }
  };

  const getFranchiseesReport = async () => {
    try {
      setFranchisees([]);
      setLoading(true);
      // const response = (await fetchFranchiseesReport(getFormattedFilters())).data;
      // setFranchisees(response);

      setFranchisees([
        { email: 'a@a.com', country: 'AR', companyId: '1122, 1133, 1144' },
        { email: 'a@a.com', country: 'UY', companyId: '2233' },
        { email: 'a@a.com', country: 'MX', companyId: '4455, 12312, 233' },
      ]);
    } catch (error) {
      showError(error);
    } finally {
      setLoading(false);
      setNewFiltersApplied(false);
    }
  };

  const setFranchisee = async (data: FranchiseesReport) => {
    try {
      setLoading(true);
      await fetchSetFranchiseesReport(data, getFormattedFilters());
      getFranchiseesReport();
    } catch (error) {
      showError(error);
    } finally {
      setLoading(false);
      setNewFiltersApplied(false);
    }
  };

  const deleteFranchisee = async (email: string) => {
    try {
      setLoading(true);
      await fetchDeleteFranchisee(email);
      getFranchiseesReport();
    } catch (error) {
      showError(error);
    } finally {
      setLoading(false);
      setNewFiltersApplied(false);
    }
  };

  return (
    <ReportContext.Provider
      value={{
        filters,
        frequency,
        newFiltersApplied,
        updateFilters,
        getFormattedFilters,
        getFilterLabels,
        getColumnColor,
        setNewFiltersApplied,
        updateFrequency,
        numberOfAppliedFilters,
        sosExclusions,
        getExclusionsReport,
        createExclusionsReport,
        updateExclusionsReport,
        deleteExclusionsReport,

        franshisees,
        getFranchiseesReport,
        setFranchisee,
        deleteFranchisee,

        loading,
      }}
    >
      {children}
    </ReportContext.Provider>
  );
};

export const useReport = () => useContext(ReportContext);
