import {
  AppBar,
  Box,
  Button,
  Chip,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  IconButton,
  InputAdornment,
  Paper,
  TextField,
  Theme,
  Toolbar,
  Typography,
  createStyles,
  makeStyles,
  useMediaQuery,
} from '@material-ui/core';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useReport } from '../../../context/report';
import { FranchiseesReport } from '../../../types';
import CountrySelector from '../selectors/CountrySelector';
import DivisionSelector from '../selectors/DivisionSelector';
import { green } from '@material-ui/core/colors';
import { Send, Clear } from '@material-ui/icons';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    body: { whiteSpace: 'break-spaces' },
    appBar: {
      position: 'relative',
    },
    title: {
      marginLeft: theme.spacing(2),
      flex: 1,
    },
    titleContainter: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      marginRight: theme.spacing(2),
    },
    buttonProgress: {
      color: green[500],
      position: 'absolute',
      top: '50%',
      left: '50%',
      marginTop: -12,
      marginLeft: -12,
    },
    loadingWrapper: {
      position: 'relative',
      display: 'flex',
      alignItems: 'center',
    },
    chipUl: {
      display: 'flex',
      justifyContent: 'start',
      position: 'relative',
      flexWrap: 'wrap',
      listStyle: 'none',
      alignItems: 'center',
      padding: theme.spacing(0.5),
      margin: 0,
      minHeight: 40,
    },
    chip: {
      margin: theme.spacing(0.5),
      height: '20px !important',
    },
    customLabel: {
      position: 'absolute',
      top: -11,
      left: 9,
      marginInline: 4,
      backgroundColor: theme.palette.background.paper,
    },
  })
);

interface Props {
  open: boolean;
  loading: boolean;
  data?: FranchiseesReport;
  handleClose: () => void;
}

const FranchiseesFormDialog = ({ open, loading, data, handleClose }: Props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { filters, setFranchisee, deleteFranchisee } = useReport();
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
  const initialFormData: FranchiseesReport = {
    country: '',
    email: '',
    companyId: '',
  };
  const [formData, setFormData] = React.useState<FranchiseesReport>(initialFormData);
  const [companyID, setCompanyID] = React.useState<string>('');
  const [companyIDs, setCompanyIDs] = React.useState<string[]>([]);
  const isEdit = Boolean(data);
  const missingData =
    filters.countries.length === 0 || formData.email === '' || companyIDs.length === 0;
  const disableSubmit = loading || missingData;

  useEffect(() => {
    if (data) {
      setFormData({ ...data });
      setCompanyIDs(data.companyId.split(','));
    } else {
      setFormData(initialFormData);
    }

    return () => {
      setFormData(initialFormData);
      setCompanyID('');
      setCompanyIDs([]);
    };
    // eslint-disable-next-line
  }, [open, data]);

  const handleID = (value: string) => {
    const regexp = /[^0-9]/g;
    if (!regexp.test(value)) {
      setCompanyID(value);
    }
  };

  const handleCloseDialog = () => {
    handleClose();
    setFormData(initialFormData);
  };

  const handleChange = (field: string, value: string) => {
    let newData = { ...formData };
    newData[field] = value.toLowerCase();
    setFormData(newData);
  };

  const handleAddID = () => {
    setCompanyIDs(state => [...state, companyID]);
    setCompanyID('');
  };

  const handleDeleteID = (index: number) => {
    let newState = [...companyIDs];
    newState.splice(index, 1);
    setCompanyIDs(newState);
  };

  const handleEnter = (key: string) => {
    if (key.toUpperCase() === 'ENTER' && companyID !== '') handleAddID();
  };

  const handleSubmit = async () => {
    const data: FranchiseesReport = {
      ...formData,
      country: filters.countries.join(),
      companyId: companyIDs.join(),
    };
    setFranchisee(data);
    handleCloseDialog();
  };

  const handleDelete = async () => {
    if (data) {
      deleteFranchisee(data.email);
      handleCloseDialog();
    }
  };

  return (
    <Dialog open={open} onClose={handleCloseDialog} maxWidth="md" fullWidth fullScreen={isMobile}>
      {isMobile && (
        <AppBar className={classes.appBar}>
          <Toolbar>
            <Typography variant="h6" className={classes.title}>
              {t('dialogs.exclusions.title')}
            </Typography>
            {isEdit && (
              <div className={classes.loadingWrapper}>
                <Button color="secondary" onClick={handleDelete} disabled={loading}>
                  {t('navigation.buttons.delete')}
                </Button>
                {loading && <CircularProgress size={22} className={classes.buttonProgress} />}
              </div>
            )}
          </Toolbar>
        </AppBar>
      )}
      {!isMobile && (
        <Box className={classes.titleContainter}>
          <DialogTitle>{t('dialogs.exclusions.title')}</DialogTitle>
          {isEdit && (
            <div className={classes.loadingWrapper}>
              <Button color="secondary" onClick={handleDelete} disabled={loading}>
                {t('navigation.buttons.delete')}
              </Button>
              {loading && <CircularProgress size={22} className={classes.buttonProgress} />}
            </div>
          )}
        </Box>
      )}
      <DialogContent>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <DialogContentText className={classes.body}>
              {t('dialogs.exclusions.body')}
            </DialogContentText>
          </Grid>
          <Grid item xs={12} md={3}>
            <DivisionSelector fullWidth disabled={isEdit} />
          </Grid>
          <Grid item xs={12} md={3}>
            <CountrySelector fullwidth disabled={isEdit} />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              required
              fullWidth
              id="email"
              size="small"
              label={'Email'}
              variant="standard"
              value={formData.email || ''}
              onChange={e => handleChange('email', e.target.value)}
              disabled={isEdit}
              style={{ marginTop: 11 }}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <TextField
              fullWidth
              id="companyId"
              size="small"
              label={t('dialogs.franchisees.addID')}
              variant="outlined"
              value={companyID}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end" style={{ opacity: 0.8 }}>
                    <IconButton size="small" onClick={handleAddID}>
                      <Send />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              onChange={e => handleID(e.target.value)}
              onKeyDown={e => handleEnter(e.key)}
            />
          </Grid>
          <Grid item xs={12} md={8}>
            <Paper component="ul" className={classes.chipUl}>
              <Box className={classes.customLabel}>
                <Typography variant="caption" style={{ opacity: 0.6 }}>
                  {t('dialogs.franchisees.companyIDs')}
                </Typography>
              </Box>
              {companyIDs.map((e, i) => (
                <li key={`${i}-${e}`}>
                  <Chip
                    label={e}
                    size="small"
                    onDelete={() => handleDeleteID(i)}
                    className={classes.chip}
                  />
                </li>
              ))}
              <Box style={{ position: 'absolute', top: 6, right: 2 }}>
                <IconButton size="small" onClick={() => setCompanyIDs([])}>
                  <Clear />
                </IconButton>
              </Box>
            </Paper>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button color="secondary" onClick={handleCloseDialog}>
          {t('navigation.buttons.cancel')}
        </Button>
        <div className={classes.loadingWrapper}>
          <Button color="primary" onClick={handleSubmit} disabled={disableSubmit}>
            {t('navigation.buttons.confirm')}
          </Button>
          {loading && <CircularProgress size={22} className={classes.buttonProgress} />}
        </div>
      </DialogActions>
    </Dialog>
  );
};

export default FranchiseesFormDialog;
