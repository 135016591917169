/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, { createContext, ReactNode, useState, useContext, useEffect } from 'react';
import { Report, MadeForYou, ChartData, LabelLanguage } from '../types';
import { useAlert } from './alert';
import {
  compareByOrderTime,
  formattedTimeIntervals,
  getExclusionRecordsLimitError,
  sortedByIntervalName,
} from '../utils/functions';
import { useReport } from './report';
import { useApi } from './api';
import { useTranslation } from 'react-i18next';
import en from '../static/locales/en.json';
import es from '../static/locales/es.json';
import fr from '../static/locales/fr.json';
import pt from '../static/locales/pt.json';
import { AxiosError } from 'axios';

interface MadeForYouContext {
  madeForYou?: Report<MadeForYou>;
  timeline: ChartData;
  dailyAverage: ChartData;
  topFiveStores: ChartData;
  bottomFiveStores: ChartData;
  topFiveManagements: ChartData;
  ranking: MadeForYou[];
  loading: boolean;
  getMadeForYou: (inExclusions?: boolean) => Promise<void>;
  getChartsData: () => Promise<void>;
  getRankingsData: () => Promise<void>;
  requestExport: () => Promise<void>;
}

const initialState: MadeForYouContext = {
  madeForYou: undefined,
  timeline: { series: [], categories: [] },
  dailyAverage: { series: [], categories: [] },
  loading: false,
  topFiveManagements: { series: [], categories: [] },
  topFiveStores: { series: [], categories: [] },
  bottomFiveStores: { series: [], categories: [] },
  ranking: [],
  getMadeForYou: () => new Promise<void>(() => {}),
  getChartsData: () => new Promise<void>(() => {}),
  getRankingsData: () => new Promise<void>(() => {}),
  requestExport: () => new Promise(() => {}),
};

const MadeForYouContext = createContext(initialState);

interface Provider {
  children: ReactNode;
}

export const MadeForYouProvider = ({ children }: Provider) => {
  const { t, i18n } = useTranslation();
  const [madeForYou, setMadeForYou] = useState<Report<MadeForYou>>();
  const [loading, setLoading] = useState(false);
  const [timeline, setTimeline] = useState<ChartData>(initialState.timeline);
  const [dailyAverage, setDailyAverage] = useState<ChartData>(initialState.dailyAverage);
  const [topFiveStores, setTopFiveStores] = useState<ChartData>(initialState.topFiveStores);
  const [topFiveManagements, setTopFiveManagements] = useState<ChartData>(
    initialState.topFiveManagements
  );
  const [bottomFiveStores, setBottomFiveStores] = useState<ChartData>(
    initialState.bottomFiveStores
  );
  const [ranking, setRanking] = useState<MadeForYou[]>([]);
  const { showError, showAlert } = useAlert();
  const { fetchMadeForYou, fetchMadeForYouRanking } = useApi();
  const {
    getFormattedFilters,
    getFilterLabels,
    newFiltersApplied,
    setNewFiltersApplied,
    filters,
  } = useReport();

  useEffect(() => {
    if (newFiltersApplied) {
      setMadeForYou(undefined);
      setTimeline({ series: [], categories: [] });
      setDailyAverage({ series: [], categories: [] });
      setTopFiveManagements({ series: [], categories: [] });
      setTopFiveStores({ series: [], categories: [] });
      setBottomFiveStores({ series: [], categories: [] });
      setRanking([]);
    }
  }, [newFiltersApplied]);

  const timelineTransformedModel = (data: MadeForYou[]) => {
    const timeline: ChartData = {
      series: [],
      categories: [],
    };
    const categories: string[] = [];
    const intervalNames: string[] = [];
    const hashmap: { [key: string]: { [key: string]: number } } = {};
    data.forEach(item => {
      if (item.businessDate && item.intervalName) {
        if (!categories.includes(item.businessDate)) categories.push(item.businessDate);
        if (!intervalNames.includes(item.intervalName)) intervalNames.push(item.intervalName);
        if (!hashmap[item.businessDate]) hashmap[item.businessDate] = {};
        hashmap[item.businessDate][item.intervalName] = item.tts || 0;
      }
    });
    categories.sort((a, b) => {
      if (a > b) return 1;
      if (a < b) return -1;
      return 0;
    });
    categories.forEach(key => {
      timeline.categories.push(key);
      const intervals = hashmap[key];
      let remainingIntervals = [...intervalNames];
      Object.keys(intervals).forEach(key => {
        remainingIntervals = remainingIntervals.filter(e => e !== key);
        const serieIndex = timeline.series.findIndex(s => s.name === formattedTimeIntervals[key]);
        if (serieIndex > -1) {
          timeline.series[serieIndex].data.push(intervals[key]);
        } else {
          timeline.series.push({ name: formattedTimeIntervals[key], data: [intervals[key]] });
        }
      });
      remainingIntervals.forEach(interval => {
        const serieIndex = timeline.series.findIndex(
          s => s.name === formattedTimeIntervals[interval]
        );
        if (serieIndex > -1) {
          timeline.series[serieIndex].data.push(0);
        } else {
          timeline.series.push({ name: formattedTimeIntervals[interval], data: [0] });
        }
      });
    });
    const sortOrder = ['Breakfast', 'Lunch', 'Snack', 'Dinner', 'Extended Hrs'];
    timeline.series.sort((a, b) => {
      if (sortOrder.indexOf(a.name) < sortOrder.indexOf(b.name)) return -1;
      if (sortOrder.indexOf(a.name) > sortOrder.indexOf(b.name)) return 1;
      return 0;
    });
    return timeline;
  };

  const dailyAverageTransformedModel = (data: MadeForYou[]) => {
    const dailyAverage: ChartData = {
      series: [{ name: '', data: [] }],
      categories: [],
    };
    data.forEach(item => {
      if (!dailyAverage.categories.includes(item.businessDate!)) {
        dailyAverage.categories.push(item.businessDate!);
        dailyAverage.series[0].data.push(item.tts);
      }
    });
    return dailyAverage;
  };

  const getTopFiveManagements = (data: MadeForYou[]) => {
    const topFiveManagementsRaw = data.sort(compareByOrderTime).slice(0, 5);
    const topFiveManagements: ChartData = {
      series: [{ name: 'MFY', data: [] }],
      categories: [],
    };
    topFiveManagementsRaw.forEach(item => {
      if (!topFiveManagements.categories.includes(item['mgmt'] as string)) {
        topFiveManagements.categories.push(item['mgmt'] as string);
        topFiveManagements.series[0].data.push(item.tts);
      }
    });
    return topFiveManagements;
  };

  const getTopFiveStores = (data: MadeForYou[]) => {
    const topFiveRaw = data.slice(0, 5);
    const topFive: ChartData = {
      series: [{ name: 'MFY', data: [] }],
      categories: [],
    };
    topFiveRaw.forEach(item => {
      if (!topFive.categories.includes(item.store!)) {
        topFive.categories.push(item.store!);
        topFive.series[0].data.push(item.tts);
      }
    });
    return topFive;
  };

  const getBottomFiveStores = (data: MadeForYou[]) => {
    const bottomFiveRaw = data.slice(data.length - 5, data.length);
    const bottomFive: ChartData = {
      series: [{ name: 'MFY', data: [] }],
      categories: [],
    };
    bottomFiveRaw.forEach(item => {
      if (!bottomFive.categories.includes(item.store!)) {
        bottomFive.categories.push(item.store!);
        bottomFive.series[0].data.push(item.tts);
      }
    });
    return bottomFive;
  };

  const getChartsData = async () => {
    try {
      setTimeline({ categories: [], series: [] });
      setDailyAverage({ categories: [], series: [] });
      setLoading(true);
      const timelineRequest = fetchMadeForYou({
        ...getFormattedFilters(),
        groupBy: 'businessDate,timeInterval',
      });
      const dailyAverageRequest = fetchMadeForYou({
        ...getFormattedFilters(),
        groupBy: 'businessDate',
      });
      const [timelineResponse, dailyAverageResponse] = await Promise.all([
        timelineRequest,
        dailyAverageRequest,
      ]);
      setTimeline(timelineTransformedModel(timelineResponse.data.results));
      setDailyAverage(dailyAverageTransformedModel(dailyAverageResponse.data.results));
    } catch (error) {
      showError(error);
    } finally {
      setLoading(false);
      setNewFiltersApplied(false);
    }
  };

  const getRankingsData = async () => {
    try {
      setLoading(true);
      const managementsRequest = fetchMadeForYouRanking({
        ...getFormattedFilters(),
        groupBy: 'mgmt',
      });
      const storesRequest = fetchMadeForYouRanking({ ...getFormattedFilters(), groupBy: 'store' });
      const [managementsResponse, storesResponse] = await Promise.all([
        managementsRequest,
        storesRequest,
      ]);
      const sortedStores = storesResponse.data.results.sort(compareByOrderTime);
      setTopFiveStores(getTopFiveStores(sortedStores));
      setBottomFiveStores(getBottomFiveStores(sortedStores));
      setTopFiveManagements(getTopFiveManagements(managementsResponse.data.results));
      const sortedMadeForYou = (
        await fetchMadeForYouRanking({
          ...getFormattedFilters(),
          groupBy: `store,${filters.rankingField}`,
        })
      ).data.results.sort(compareByOrderTime);
      setRanking(sortedMadeForYou);
    } catch (error) {
      showError(error);
    } finally {
      setLoading(false);
      setNewFiltersApplied(false);
    }
  };

  const getTotalStores = (results: MadeForYou[]) => {
    let count = 0;
    let stores: { [key: string]: number } = {};
    results.forEach((e: MadeForYou) => {
      if (e.store && !stores[e.store]) {
        stores[e.store] = 1;
        count++;
      }
    });
    return `#${count}`;
  };

  const getMadeForYou = async (inExclusions?: boolean) => {
    try {
      setMadeForYou(undefined);
      setLoading(true);
      const response = (await fetchMadeForYou(getFormattedFilters(inExclusions))).data;
      getTotalStores(response.results);
      setMadeForYou({
        totals: { ...response['result_totals'][0], store: getTotalStores(response.results) },
        data: sortedByIntervalName(response.results) as MadeForYou[],
      });
    } catch (error) {
      const customError = inExclusions
        ? getExclusionRecordsLimitError(error as AxiosError)
        : undefined;
      showError(error, customError);
    } finally {
      setLoading(false);
      setNewFiltersApplied(false);
    }
  };

  const requestExport = async () => {
    try {
      setLoading(true);
      let labels: LabelLanguage = {};
      switch (i18n.language) {
        case 'en':
          labels = { ...en.reports.labels };
          break;
        case 'es':
          labels = { ...es.reports.labels };
          break;
        case 'fr':
          labels = { ...fr.reports.labels };
          break;
        case 'pt':
          labels = { ...pt.reports.labels };
          break;
        default:
          break;
      }
      showAlert(t('dialogs.exportRequested.title'), t('dialogs.exportRequested.body'));
      await fetchMadeForYou(getFormattedFilters(), true, labels, getFilterLabels());
    } catch (error) {
      showError(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <MadeForYouContext.Provider
      value={{
        madeForYou,
        timeline,
        dailyAverage,
        topFiveManagements,
        topFiveStores,
        bottomFiveStores,
        ranking,
        getMadeForYou,
        getRankingsData,
        getChartsData,
        requestExport,
        loading,
      }}
    >
      {children}
    </MadeForYouContext.Provider>
  );
};

export const useMadeForYou = () => useContext(MadeForYouContext);
