import React, { lazy } from 'react';
import { Switch, Redirect } from 'react-router-dom';
import { PrivateRoute } from '../../../Router';

const Report = lazy(() => import('./report/detailed'));
const Charts = lazy(() => import('./charts'));
const Rankings = lazy(() => import('./rankings'));

const DriveThruRouter = () => (
  <Switch>
    <PrivateRoute path="/drivethru/report" component={Report} />
    <PrivateRoute path="/drivethru/charts" component={Charts} />
    <PrivateRoute path="/drivethru/rankings" component={Rankings} />
    <Redirect from="/drivethru" to="/drivethru/report" />
  </Switch>
);

export default DriveThruRouter;
