import React, { lazy } from 'react';
import { Switch, Redirect } from 'react-router-dom';
import { PrivateRoute } from '../../../Router';

const Report = lazy(() => import('./report/detailed'));
const Charts = lazy(() => import('./charts'));
const Rankings = lazy(() => import('./rankings'));

const MadeForYouRouter = () => (
  <Switch>
    <PrivateRoute path="/madeforyou/report" component={Report} restriction="SCREEN_MFY_REPORT" />
    <PrivateRoute path="/madeforyou/charts" component={Charts} />
    <PrivateRoute path="/madeforyou/rankings" component={Rankings} />
    <Redirect from="/madeforyou" to="/madeforyou/report" />
  </Switch>
);

export default MadeForYouRouter;
