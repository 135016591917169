/* eslint-disable @typescript-eslint/no-empty-function */
import React, { createContext, ReactNode, useContext, useState, useEffect } from 'react';
import { useAlert } from './alert';
import { AppPermission } from '../types';
import { useAuthentication } from './authentication';
import { useTranslation } from 'react-i18next';
import { useApi } from './api';

const SCREEN_ROUTES: { [key: string]: string } = {
  SCREEN_MFY_REPORT: '/madeforyou/report',
  SCREEN_MFY_CHARTS: '/madeforyou/charts',
  SCREEN_MFY_RANKINGS: '/madeforyou/rankings',
  SCREEN_DT_REPORT: '/drivethru/report',
  SCREEN_DT_CHARTS: '/drivethru/charts',
  SCREEN_DT_RANKINGS: '/drivethru/rankings',
  SCREEN_FC_REPORT: '/drivethru/report',
  SCREEN_FC_CHARTS: '/drivethru/charts',
  SCREEN_FC_RANKINGS: '/drivethru/rankings',
};
interface UserContext {
  permissions: AppPermission[];
  loading: boolean;
  getAllowedScreens: () => string[];
}

const initialState: UserContext = {
  permissions: [],
  loading: false,
  getAllowedScreens: () => [],
};

const UserContext = createContext(initialState);

interface Provider {
  children: ReactNode;
}

export const UserProvider = ({ children }: Provider) => {
  const [permissions, setPermissions] = useState<AppPermission[]>([]);
  const [loading, setLoading] = useState(false);
  const { showAlert, showError } = useAlert();
  const { isAuthenticated } = useAuthentication();
  const { fetchPermissions } = useApi();
  const { t } = useTranslation();

  const getAllowedScreens = () => {
    const allowedScreens: string[] = [];
    permissions.forEach(permission => {
      if (/SCREEN_*/.test(permission) && SCREEN_ROUTES[permission])
        allowedScreens.push(SCREEN_ROUTES[permission]);
    });

    console.log('allowedScreens', { allowedScreens });
    return allowedScreens;
  };

  useEffect(() => {
    const init = async () => {
      try {
        setLoading(true);
        const fetchedPermissions = (await fetchPermissions()).data;
        setPermissions(fetchedPermissions);
        if (fetchedPermissions.length === 0) {
          showAlert(t('errors.permissions.title'), t('errors.permissions.no_permission'), [
            {
              label: t('errors.permissions.backToTiles'),
              handleClick: () =>
                (window.location.href =
                  process.env.REACT_APP_LAUNCHER_URL || 'https://flex-dev.arcosdorados.net'),
            },
          ]);
        }
      } catch (error) {
        showError(error);
      } finally {
        setLoading(false);
      }
    };
    if (isAuthenticated) {
      init();
    }
    // eslint-disable-next-line
  }, [isAuthenticated]);

  return (
    <UserContext.Provider
      value={{
        permissions,
        loading,
        getAllowedScreens,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export const useUser = () => useContext(UserContext);
