import React, { useEffect } from 'react';
import Avatar from '@material-ui/core/Avatar';
import CssBaseline from '@material-ui/core/CssBaseline';
import Link from '@material-ui/core/Link';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles, Theme } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { useAuthentication } from '../../context/authentication';
import { useUser } from '../../context/user';
import { useTranslation } from 'react-i18next';
import { Button, CircularProgress } from '@material-ui/core';
import { ReactComponent as MSLogo } from '../../static/assets/ms-logo.svg';
import { useHistory } from 'react-router-dom';

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://www.arcosdorados.com/">
        Arcos Dorados
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  button: {
    marginTop: theme.spacing(1),
    background: theme.palette.type === 'light' ? '#FFF' : '#2F2F2F',
    color: theme.palette.type === 'light' ? '#5E5E5E' : '#FFF',
    // fontFamily: 'Segoe UI',
    borderRadius: 0,
    textTransform: 'none',
    fontSize: '15px',
    fontWeight: 500,
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  loadingWrapper: {
    margin: theme.spacing(1),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  progressAvatar: {
    position: 'absolute',
    zIndex: 1,
  },
}));

const Login = () => {
  const classes = useStyles();
  const { login, loading } = useAuthentication();
  const { loading: userLoading } = useUser();
  const { t } = useTranslation();
  const { permissions } = useUser();
  const history = useHistory();

  useEffect(() => {
    console.log('Login');
    if (permissions && permissions.length > 0) history.push('/madeforyou/report');

    // eslint-disable-next-line
  }, [permissions, history]);

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <div className={classes.loadingWrapper}>
          {(loading || userLoading) && (
            <CircularProgress className={classes.progressAvatar} size={48} color="secondary" />
          )}
          <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar>
        </div>
        <Typography component="h1" variant="h5">
          Speed of Service
        </Typography>
        <Button
          onClick={() => login()}
          className={classes.button}
          startIcon={<MSLogo />}
          variant="outlined"
          disabled={loading || userLoading}
        >
          {t('login.labels.signInWithMS')}
        </Button>
      </div>
      <Box mt={8}>
        <Copyright />
      </Box>
    </Container>
  );
};

export default Login;
