import React from 'react';
import {
  makeStyles,
  Theme,
  createStyles,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Checkbox,
  ListItemText,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import { useReport } from '../../../context/report';
import { useTranslation } from 'react-i18next';
import { useInstitutional } from '../../../context/institutional';
import { useLocation } from 'react-router-dom';
import clsx from 'clsx';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      margin: theme.spacing(1),
      minWidth: 90,
    },
    fullWidth: {
      width: '100%',
    },
  })
);

interface DivisionSelector {
  noLabel?: boolean;
  fullWidth?: boolean;
  value?: string;
  disabled?: boolean;
}

const DivisionSelector = ({ noLabel, fullWidth, value, disabled }: DivisionSelector) => {
  const { filters, updateFilters } = useReport();
  const { filteredDivisions } = useInstitutional();
  const classes = useStyles();
  const { t } = useTranslation();
  const location = useLocation();
  const theme = useTheme();
  const smDown = useMediaQuery(theme.breakpoints.down('sm'));
  const disableSelector = disabled || (filteredDivisions.length < 2 && filters.division.length > 0);
  const singleDivisionSelection =
    location.pathname.includes('report') ||
    location.pathname.includes('charts') ||
    location.pathname.includes('rankings') ||
    location.pathname.includes('exclusions') ||
    location.pathname.includes('franchisees');

  const renderValue = (selected: string[]) => {
    if (smDown) {
      if ((selected as string[]).length > 1) {
        return `${(selected as string[]).length} ${t('shared.labels.selected')}`;
      } else {
        return t(`shared.labels.${(selected as string[])[0]}`);
      }
    } else {
      return (selected as string[]).map(option => t(`shared.labels.${option}`)).join(', ');
    }
  };

  const handleSelectDivisions = (event: React.ChangeEvent<{ value: unknown }>) => {
    const selection = event.target.value as string[];
    updateFilters({ ...filters, division: selection });
  };

  const handleSelectDivision = (event: React.ChangeEvent<{ value: unknown }>) => {
    updateFilters({ ...filters, division: [event.target.value as string] });
  };

  if (singleDivisionSelection) {
    return (
      <FormControl className={clsx(classes.formControl, fullWidth ? classes.fullWidth : undefined)}>
        {!noLabel && (
          <InputLabel id="division-simple-select-label">{t('reports.labels.division')}</InputLabel>
        )}
        <Select
          labelId="division-simple-select-label"
          id="division-simple-select"
          value={value || filters.division[0] || ''}
          onChange={handleSelectDivision}
          required
          disabled={disableSelector}
          autoWidth
          renderValue={selected => {
            return t(`shared.labels.${selected as string}`);
          }}
        >
          {filteredDivisions &&
            filteredDivisions.map(div => (
              <MenuItem key={div} value={div}>
                <Checkbox checked={filters.division.indexOf(div) > -1} />
                <ListItemText primary={t(`shared.labels.${div}`)} />
              </MenuItem>
            ))}
        </Select>
      </FormControl>
    );
  } else {
    return (
      <FormControl className={clsx(classes.formControl, fullWidth ? classes.fullWidth : undefined)}>
        {!noLabel && (
          <InputLabel id="division-simple-select-label">{t('reports.labels.division')}</InputLabel>
        )}
        <Select
          labelId="division-simple-select-label"
          id="division-simple-select"
          value={value ? [value] : filters.division}
          onChange={handleSelectDivisions}
          required
          disabled={disableSelector}
          multiple
          MenuProps={{ getContentAnchorEl: null }}
          autoWidth
          renderValue={selected => renderValue(selected as string[])}
        >
          {filteredDivisions &&
            filteredDivisions.map(div => (
              <MenuItem key={div} value={div}>
                <Checkbox checked={filters.division.indexOf(div) > -1} />
                <ListItemText primary={t(`shared.labels.${div}`)} />
              </MenuItem>
            ))}
        </Select>
      </FormControl>
    );
  }
};

export default DivisionSelector;
