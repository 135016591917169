/* eslint-disable @typescript-eslint/no-empty-function */
import React, { createContext, ReactNode, useState, useContext, useEffect } from 'react';
import { Report, DriveThru, ChartData, LabelLanguage } from '../types';
import { useAlert } from './alert';
import { useReport } from './report';
import {
  timelineTransformedModel,
  dailyAverageTransformedModel,
  compareByOepeTime,
  getTopFiveManagements,
  getTopFiveStores,
  getBottomFiveStores,
  sortedByIntervalName,
  getExclusionRecordsLimitError,
} from '../utils/functions';
import { useApi } from './api';
import { useTranslation } from 'react-i18next';
import en from '../static/locales/en.json';
import es from '../static/locales/es.json';
import fr from '../static/locales/fr.json';
import pt from '../static/locales/pt.json';
import { AxiosError } from 'axios';

interface DriveThruContext {
  driveThru?: Report<DriveThru>;
  timeline: ChartData;
  dailyAverage: ChartData;
  topFiveStores: ChartData;
  bottomFiveStores: ChartData;
  topFiveManagements: ChartData;
  ranking: DriveThru[];
  loading: boolean;
  getDriveThru: (inExclusions?: boolean) => Promise<void>;
  getChartsData: () => Promise<void>;
  getRankingsData: () => Promise<void>;
  requestExport: () => Promise<void>;
}

const initialState: DriveThruContext = {
  driveThru: undefined,
  timeline: { series: [], categories: [] },
  dailyAverage: { series: [], categories: [] },
  topFiveManagements: { series: [], categories: [] },
  topFiveStores: { series: [], categories: [] },
  bottomFiveStores: { series: [], categories: [] },
  ranking: [],
  loading: false,
  getDriveThru: () => new Promise<void>(() => {}),
  getChartsData: () => new Promise<void>(() => {}),
  getRankingsData: () => new Promise<void>(() => {}),
  requestExport: () => new Promise(() => {}),
};

const DriveThruContext = createContext(initialState);

interface Provider {
  children: ReactNode;
}

export const DriveThruProvider = ({ children }: Provider) => {
  const { t, i18n } = useTranslation();
  const [driveThru, setData] = useState<Report<DriveThru>>();
  const [timeline, setTimeline] = useState<ChartData>(initialState.timeline);
  const [dailyAverage, setDailyAverage] = useState<ChartData>(initialState.dailyAverage);
  const [topFiveStores, setTopFiveStores] = useState<ChartData>(initialState.topFiveStores);
  const [topFiveManagements, setTopFiveManagements] = useState<ChartData>(
    initialState.topFiveManagements
  );
  const [bottomFiveStores, setBottomFiveStores] = useState<ChartData>(
    initialState.bottomFiveStores
  );
  const [ranking, setRanking] = useState<DriveThru[]>([]);
  const [loading, setLoading] = useState(false);
  const { showError, showAlert } = useAlert();
  const { fetchDriveThru, fetchDriveThruRanking } = useApi();
  const {
    getFormattedFilters,
    getFilterLabels,
    newFiltersApplied,
    setNewFiltersApplied,
    filters,
  } = useReport();

  useEffect(() => {
    if (newFiltersApplied) {
      setData(undefined);
      setTimeline({ series: [], categories: [] });
      setDailyAverage({ series: [], categories: [] });
      setTopFiveManagements({ series: [], categories: [] });
      setTopFiveStores({ series: [], categories: [] });
      setBottomFiveStores({ series: [], categories: [] });
      setRanking([]);
    }
  }, [newFiltersApplied]);

  const getTotalStores = (results: DriveThru[]) => {
    let count = 0;
    let stores: { [key: string]: number } = {};
    results.forEach((e: DriveThru) => {
      if (e.store && !stores[e.store]) {
        stores[e.store] = 1;
        count++;
      }
    });
    return `#${count}`;
  };

  const getDriveThru = async (inExclusions?: boolean) => {
    try {
      setData(undefined);
      setLoading(true);
      const response = (await fetchDriveThru(getFormattedFilters(inExclusions))).data;
      setData({
        totals: { ...response['result_totals'][0], store: getTotalStores(response.results) },
        data: sortedByIntervalName(response.results) as DriveThru[],
      });
    } catch (error) {
      const customError = inExclusions
        ? getExclusionRecordsLimitError(error as AxiosError)
        : undefined;
      showError(error, customError);
    } finally {
      setLoading(false);
      setNewFiltersApplied(false);
    }
  };

  const getChartsData = async () => {
    try {
      setTimeline({ categories: [], series: [] });
      setDailyAverage({ categories: [], series: [] });
      setLoading(true);
      const timelineRequest = fetchDriveThru({
        ...getFormattedFilters(),
        groupBy: 'businessDate,timeInterval',
      });
      const dailyAverageRequest = fetchDriveThru({
        ...getFormattedFilters(),
        groupBy: 'businessDate',
      });
      const [timelineResponse, dailyAverageResponse] = await Promise.all([
        timelineRequest,
        dailyAverageRequest,
      ]);
      setTimeline(timelineTransformedModel(timelineResponse.data.results));
      setDailyAverage(dailyAverageTransformedModel(dailyAverageResponse.data.results));
    } catch (error) {
      showError(error);
    } finally {
      setLoading(false);
      setNewFiltersApplied(false);
    }
  };

  const getRankingsData = async () => {
    try {
      setLoading(true);
      const operativeRankingRequest = fetchDriveThruRanking({
        ...getFormattedFilters(),
        groupBy: filters.rankingField,
      });
      const storesRequest = fetchDriveThruRanking({ ...getFormattedFilters(), groupBy: 'store' });
      const [operativeResponse, storesResponse] = await Promise.all([
        operativeRankingRequest,
        storesRequest,
      ]);
      const sortedStores = storesResponse.data.results.sort(compareByOepeTime);
      setTopFiveStores(getTopFiveStores(sortedStores));
      setBottomFiveStores(getBottomFiveStores(sortedStores));
      setTopFiveManagements(
        getTopFiveManagements(operativeResponse.data.results, filters.rankingField)
      );
      const sortedDriveThru = (
        await fetchDriveThruRanking({
          ...getFormattedFilters(),
          groupBy: `store,${filters.rankingField}`,
        })
      ).data.results.sort(compareByOepeTime);
      setRanking(sortedDriveThru);
    } catch (error) {
      showError(error);
    } finally {
      setLoading(false);
      setNewFiltersApplied(false);
    }
  };

  const requestExport = async () => {
    try {
      setLoading(true);
      let labels: LabelLanguage = {};
      switch (i18n.language) {
        case 'en':
          labels = { ...en.reports.labels };
          break;
        case 'es':
          labels = { ...es.reports.labels };
          break;
        case 'fr':
          labels = { ...fr.reports.labels };
          break;
        case 'pt':
          labels = { ...pt.reports.labels };
          break;
        default:
          break;
      }
      showAlert(t('dialogs.exportRequested.title'), t('dialogs.exportRequested.body'));
      await fetchDriveThru(getFormattedFilters(), true, labels, getFilterLabels());
    } catch (error) {
      showError(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <DriveThruContext.Provider
      value={{
        driveThru,
        timeline,
        dailyAverage,
        topFiveManagements,
        topFiveStores,
        bottomFiveStores,
        ranking,
        getChartsData,
        getDriveThru,
        getRankingsData,
        requestExport,
        loading,
      }}
    >
      {children}
    </DriveThruContext.Provider>
  );
};

export const useDriveThru = () => useContext(DriveThruContext);
